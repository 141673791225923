import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBloods(ctx, data) {
      return useJwt.getBloods(data).then(response => response);
    },
    createBloodGroup(ctx, data) {
      return useJwt.createBlood(data).then(response => response);
    },
    updateBloodGroup(ctx, data) {
      return useJwt.updateBlood(data).then(response => response);
    },
    deleteBloodGroup(ctx, id) {
      return useJwt.deleteBlood(id).then(response => response);
    },
  },
};
