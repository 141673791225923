import { render, staticRenderFns } from "./BloodGroupList.vue?vue&type=template&id=f9b2aa10&scoped=true&"
import script from "./BloodGroupList.vue?vue&type=script&lang=js&"
export * from "./BloodGroupList.vue?vue&type=script&lang=js&"
import style0 from "./BloodGroupList.vue?vue&type=style&index=0&id=f9b2aa10&lang=scss&scoped=true&"
import style1 from "./BloodGroupList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9b2aa10",
  null
  
)

export default component.exports